import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Button from "../../components/button";
import Icon from "../../components/icon";
import SplitHero from "../../components/split-hero";
import Tag, { TagGroup } from "../../components/tag";
import Text from "../../components/text";

function AndyKaufmanSection() {
  const { andyScreenshot1 } = useStaticQuery(
    graphql`
      query {
        andyScreenshot1: file(relativePath: { eq: "andy-screenshot-1.jpg" }) {
          ...fluidImage
        }
      }
    `
  );

  return (
    <SplitHero>
      <SplitHero.Content>
        <SplitHero.Heading>
          <Text variant="h3" component="h2" shade="light">
            Mr Andy Kaufman's Gone Wrestling.
          </Text>
        </SplitHero.Heading>

        <Text variant="p" shade="mid">
          A game that allows you to control Andy "I'm from Hollywood" Kaufman
          during his infamous wrestling match on April 5th, 1982 in Memphis,
          Tennessee as he attempts to defeat his first male opponent, the
          southern heavyweight champion Jerry "The King" Lawler.
          <TagGroup>
            <Tag>3D</Tag>
            <Tag>CSS Animation</Tag>
            <Tag>CSS Transforms</Tag>
            <Tag>CSS</Tag>
            <Tag>Game</Tag>
            <Tag>Javascript</Tag>
          </TagGroup>
        </Text>

        <Button
          href="https://github.com/Steveeeie/andy-kaufman-wrestling-game"
          as="a"
          target="__blank"
          rel="nofollow"
          marginTop={4}
          small
        >
          View On GitHub <Icon icon="github" marginLeft={0} />
        </Button>
      </SplitHero.Content>

      <SplitHero.Media>
        <SplitHero.Media.Content>
          <Img
            fluid={andyScreenshot1.childImageSharp.fluid}
            alt="Andy Kaufman Wrestling Game"
          />
        </SplitHero.Media.Content>
      </SplitHero.Media>
    </SplitHero>
  );
}

export default AndyKaufmanSection;
